import {
  Container,
  Stack,
  Button,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import useLocales from '#/lib/hooks/useLocales';
import IntroHead from '#/components/shared/IntroHead';
import Iconify from '#/components/shared/ui/Iconify';
import FileManagerFolderItem from '#/components/shared/file-manager/file-manager-folder-item';

export default function DFPhotosVideos() {
  const [view, setView] = useState<string>('photos');

  const { translate } = useLocales();
  const navigate = useNavigate();

  const handleBackToDashboard = () => {
    navigate('/dashboard/digitaler-nachlass');
  };

  const handleChangeView = useCallback(
    (event: React.MouseEvent<HTMLElement>, newView: string | null) => {
      if (newView !== null) {
        setView(newView);
      }
    },
    []
  );

  return (
    <Container>
      <Stack direction="row" justifyContent="center">
        <Button onClick={handleBackToDashboard} sx={{ width: 'fit-content' }}>
          <Typography>{String(translate('global.back'))}</Typography>
        </Button>
      </Stack>
      <IntroHead
        title={String(translate('dfPhotosVideos.introHead'))}
        description={String(translate('dfPhotosVideos.introDescription'))}
      />
      <ToggleButtonGroup
        size="small"
        value={view}
        exclusive
        onChange={handleChangeView}
      >
        <ToggleButton value="list">
          <Iconify icon="solar:list-bold" />
        </ToggleButton>

        <ToggleButton value="grid">
          <Iconify icon="mingcute:dot-grid-fill" />
        </ToggleButton>
      </ToggleButtonGroup>
      <FileManagerFolderItem
        folder={{
          id: '1',
          name: 'Folder Name',
          url: 'https://www.google.com',
          shared: null,
          tags: ['tag1', 'tag2'],
          size: 123456789,
          totalFiles: 5,
          createdAt: new Date(),
          modifiedAt: new Date(),
          isFavorited: false,
          type: 'folder',
        }}
        onDelete={() => {}}
      />
    </Container>
  );
}
